import React, { useState } from 'react';
import Navbar from '../Navbar';
import BeeImage from '../images/BEE.png';
import { InputGroup, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MessageBox from '../MessageBox';

const Subscribe = () => {
    var responseString = "";
    const [phoneNumber, setPhoneNumber] = useState('');
    const [error, setError] = useState('');

    const handleChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    const handleButton = async () => {
        const result = await MessageBox.open({
            content: <p>{responseString}</p>,
            buttons: [{ name: "ok", handler: () => "Ok" }]
        });
    };

    const handleSubscribe = () => {
        if (phoneNumber.trim() === '') {
            setError('Mobile number is empty');
        }
        else if (phoneNumber.length !== 10) {
            setError('Invalid phone number. Must be exactly 10 digits');
        }
        else {
            setError('');
            // console.log('Subscribed with phone number:', phoneNumber);

            if (phoneNumber.length === 10) {
                subscribeQuizzibee();
            }
        }
    };

    const subscribeQuizzibee = async () => {
        const phoneNo = '92' + phoneNumber.trim();
        const sendPinUrl = `http://api.quizzibee.com/api/subscribers?msisdn=${encodeURIComponent(phoneNo)}&txid=${encodeURIComponent('1234')}`;
        try {
            const response = await fetch(sendPinUrl);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();

            if (data) {
                // console.log("data", data);
                responseString=data.message;
                handleButton();
            } else {
                throw new Error('Empty response received');
            }
        } catch (error) {
            console.error("Error :", error);
        }
    };


    return (
        <>
            <div className="wrapper">
                {/* Navbar Section */}
                <div style={{ position: "static" }}>
                    <Navbar />
                </div>
                {/* Navbar End */}

                <div id="main" className="main-content">
                    <div id="home" className="home">
                        {/* Hero Section*/}
                        <div className="container">
                            <div className="row text-center">
                                <div className="col-md-12">
                                    <div className="hero-content wow fadeIn">
                                        <img className="banner-left" src={BeeImage} alt=""></img>
                                        <div className="text-content">
                                            <div style={{ marginLeft: '80px' }}>
                                                <h1>Subscribe Now!</h1>
                                                <h4 style={{ display: 'flex', margin: '10px' }}>Subscribe to QuizziBee now for only 15 PKR Per Week inclusive of taxes.</h4>
                                                <h4 style={{ marginBottom: '20px' }}>Enter your phone number and enjoy all interesting content from us.</h4>
                                                <InputGroup style={{ minWidth: '280px' }} >
                                                    <InputGroup.Text>+92</InputGroup.Text>
                                                    <Form.Control
                                                        placeholder='Enter your Phone Number'
                                                        value={phoneNumber}
                                                        onChange={handleChange}
                                                    />
                                                </InputGroup>
                                                {error && <h4 style={{ color: 'red' }}>{error}</h4>}
                                                <button className="btn-action" onClick={handleSubscribe}>Subscribe</button>
                                                <Link style={{ marginLeft: '20px' }} to={'/'}>Cancel</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Subscribe;
