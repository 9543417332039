import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import '../App.css';

const LeaderBoard = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage] = useState(10); // Number of entries to display per page
    const [data, setData] = useState([]); // State to hold leaderboard data
    const [week, setWeek] = useState([]); // State to hold leaderboard data
    const [startDate, setStartDate] = useState([]); // State to hold leaderboard data
    const [endDate, setEndDate] = useState([]); // State to hold leaderboard data
    const [showHeader, setShowHeader]=useState(false);
    useEffect(() => {
        // Fetch data from the API
        fetch(`http://api.quizzibee.com/api/get-weekly-record?start_date=${encodeURIComponent('2024-02-12')}&end_date=${encodeURIComponent('2024-02-18')}`)
            .then(response => response.json())
            .then(result => {
                setShowHeader(true);
                // Update the data state with the fetched results
                setData(result);
                setWeek(result[0].week_no);
                setStartDate(result[0].start_date);
                setEndDate(result[0].end_date);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const filterTable = (event) => {
        // Your filtering logic
    };

    // Logic to calculate the indexes of entries to display
    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;

    return (
        <>
            <div className="wrapper">
                <Navbar />
                <div id="main" className="main-content">
                    <div id="tnc" className="tnc">
                        <div className="container">
                            <div className="row text-center">
                                <div className="col-md-12">
                                    <div className="hero-content wow fadeIn">
                                        <div className="text-content">
                                            <h1>Top Players of the week.</h1>
                                            <h2> Join QuizziBee challenges to participate in weekly rewards.</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* History Table Start */}
                    <div id="main" className="main-content">
                        <div id="contact-wrapper" className="contact-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="contact-content">
                                            <div className="contact-desc-title">
                                                <h1 style={{ display: 'flex', marginBottom: '10px' }}>Top Players Details</h1>
                                                {showHeader && (
                                                    <h3 style={{ margin: '20px' }}>This is the current Week {week} starting from {startDate.substring(0, 10)} and will end on {endDate.substring(0, 10)} </h3>

                                                )}
                                            </div>
                                            
                                            <table id="myTable"  className="table table-striped table-responsive">
                                                <thead>
                                                    <tr className="header">
                                                        <th style={{ width: '10%' }}>Sr. no.</th>
                                                        <th style={{ width: '10%' }}>Player Names</th>
                                                        <th style={{ width: '8%' }}>Week no.</th>
                                                        <th style={{ width: '10%' }}>Start Date</th>
                                                        <th style={{ width: '10%' }}>End Date</th>
                                                        <th style={{ width: '8%' }}>Games played</th>
                                                        <th style={{ width: '8%' }}>Total Score</th>
                                                        <th style={{ width: '8%' }}>Games Won</th>
                                                        <th style={{ width: '8%' }}>Games Lost</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* Render only the entries for the current page */}
                                                    {data.slice(indexOfFirstEntry, indexOfLastEntry).map((entry, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{entry.player_name}</td>
                                                            <td>{entry.week_no}</td>
                                                            <td>{entry.start_date.substring(0, 10)}</td>
                                                            <td>{entry.end_date.substring(0, 10)}</td>
                                                            <td>{entry.games_played}</td>
                                                            <td>{entry.total_score}</td>
                                                            <td>{entry.games_won}</td>
                                                            <td>{entry.games_lost}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            {/* Pagination controls */}
                                            <div className="pagination">
                                                <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
                                                    Previous Page
                                                </button>
                                                <button onClick={() => setCurrentPage(currentPage + 1)} disabled={indexOfLastEntry >= data.length}>
                                                    Next Page
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* History Table End */}
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default LeaderBoard;
