import React from 'react';
import Navbar from '../Navbar'; // Import your Navbar component
import Footer from '../Footer'; // Import your Footer component
import '../App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Contact = () => {
  return (
    <div className="wrapper">
      <Navbar />
      <div id="main" className="main-content">
        <div id="tnc" className="tnc">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12">
                <div className="hero-content wow fadeIn">
                  <div className="text-content">
                    <h1>Contact Us</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="contact-wrapper" className="contact-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="contact-content">
                  <div className="contact-desc-title">
                    <h1>Contact Details</h1>
                  </div>
                  <div className="contact-detail">
                    <ul>
                      <li>
                        <i className="fa fa-envelope"></i>
                        <a href="mailto:support@quizzibee.com">support@quizzibee.com</a>
                      </li>
                      <li>
                        <i className="fa fa-phone"></i>
                        <a href="tel:03111444974">03111444974</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Contact;
