import React from 'react';
import Navbar from '../Navbar'; // Import your Navbar component
import Footer from '../Footer'; // Import your Footer component
import '../App.css';
const Tnc = () => {
  return (
    <div className="wrapper">
      <Navbar />
      <div id="main" className="main-content">
        <div id="tnc" className="tnc">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12">
                <div className="hero-content wow fadeIn">
                  <div className="text-content">
                    <h1>Terms and Conditions (T&amp;C)</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="tnc-wrapper" className="tnc-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="content">
                  <p>All content e.g. games, rewards, and subscriptions from QuizziBee are subject to these Term of Use.</p>
                  <ol>
                    <li>Introduction</li>
                    <ol className="dashed">
                      <li>QuizziBee is an online game playing portal that allows you to test your observation skills in modern and effective way.</li>
                      <li>
                        By using any part of QuizziBee, you represent that you have read, understood, and agreed to be bound by these Terms and Conditions including any future modifications. QuizziBee may amend, update or change these Terms and Conditions.
                      </li>
                    </ol>
                    <li>
                      QuizziBee allows you to games, see the top players scores, and get weekly rewards.
                    </li>
                    <li>Payment and Service Access :</li>
                    <ul>
                      <li>You can subscribe to the service through the portal by clicking the subscribe button and you will then receive an SMS notification which will be sent to your registered number.</li>
                    </ul>
                    <li>Price and payment are expressly stated that the cost of connection to the service is supported by the user, which includes the content downloaded by users. This shall remain at the responsibility of the user.</li>
                    <li>Rules of use of the Service principles governing the use of service is exclusively reserved for a practical and non-commercial use.</li>
                    <li>You understand the characteristic and limitations of electronic communications networks, including the technical performance, response, querying, transferring data and risk to information security.</li>
                    <li>To terminate the Service, kindly send</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Tnc;
