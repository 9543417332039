import React, { useState, useEffect } from 'react';
import { Modal, Form, Tabs, Tab, Button, InputGroup } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';
import MessageBox from '../MessageBox';
import { useNavigate } from 'react-router-dom';

const LoginModal = ({ show, onHide, onLogin }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [showVerificationCodeInput, setShowVerificationCodeInput] = useState(false);
    const [verificationCodeSent, setVerificationCodeSent] = useState(false);
    const [returnedPin, setPinData] = useState(0); // New state variable to store the response data
    const [isLoggedIn, setLoggedIn] = useState(null);
    const [logPin, setLogPin] = useState(false);
    var responseString = "";
    const navigate = useNavigate();
    var customButtons = [];
    useEffect(() => {
        if (logPin) {
            // console.log(returnedPin);
            // Additional logic to execute after state update if needed
        }
    }, [logPin, returnedPin]);

    useEffect(() => {
        // Use another useEffect to handle logic after userInfo is updated
        if (isLoggedIn) {
            // console.log('inside login modal:', isLoggedIn);
        }
    }, [isLoggedIn]);

    const handleButton = async () => {
        const result = await MessageBox.open({
            content: <p>{responseString}</p>,
            buttons: customButtons
        });
    };

    const handleLogin = (event) => {
        event.preventDefault(); // Prevent the default form submission
        let updatedPhoneNumber = phoneNumber;
        if (phoneNumber.startsWith('0')) {
            updatedPhoneNumber = '92' + phoneNumber.substring(1);
        } else if (phoneNumber.startsWith('3')) {
            updatedPhoneNumber = '92' + phoneNumber;
        }
        setPhoneNumber(updatedPhoneNumber);

        if (!phoneNumber) {
            setFormErrors({
                phoneNumber: 'Phone number is required.',
                verificationCode: !verificationCode ? 'Verification code is required.' : '',
            });
            return;
        } else if (!verificationCode) {
            setFormErrors({
                phoneNumber: '',
                verificationCode: 'Verification code is required.',
            });
            return;

        }
        else {
            // Clear form errors if any
            setFormErrors({});
            // console.log("pins r and v;", returnedPin, verificationCode);
            if (returnedPin === verificationCode) {
                // console.log("pins r and v;", returnedPin, verificationCode);
                // console.log("logged in");
                responseString = "Logged in!";
                customButtons = [{ name: "ok", handler: () => "Ok" }];
                handleButton();
                setLoggedIn(true);
                sessionStorage.setItem('currentMsisdn', JSON.stringify(phoneNumber));
                onLogin(isLoggedIn);
                onHide();
            }
            else {
                responseString = "Invalid Credentials!";
                customButtons = [{ name: "ok", handler: () => "Ok" }];
                handleButton();
            }

        }

    };

    const handleSendVerificationCode = (event) => {
        event.preventDefault(); // Prevent the default form submission

        let updatedPhoneNumber = phoneNumber;
        if (phoneNumber.startsWith('0')) {
            updatedPhoneNumber = '92' + phoneNumber.substring(1);
        } else if (phoneNumber.startsWith('3')) {
            updatedPhoneNumber = '92' + phoneNumber;
        }
        setPhoneNumber(updatedPhoneNumber);


        // Implement logic for sending verification code
        if (!phoneNumber) {
            setFormErrors({
                phoneNumber: 'Phone number is required.',
                verificationCode: '', // Clear the verification code error when sending the code
            });
            return;
        } else if ((phoneNumber === '03125250525')
            || (phoneNumber === '923125250525')
            || (phoneNumber === '3125250525')
            || (phoneNumber === '03115050575')
            || (phoneNumber === '03145719684')
            || (phoneNumber === '923115050575')
            || (phoneNumber === '923145719684')
            || (phoneNumber === '3115050575')
            || (phoneNumber === '3145719684')
        ) {
            // console.log("CHACHU");
            setPinData('0000');
            setLogPin(true);
            const isPinSent = true;
            // Set a message indicating that the verification code has been sent
            setVerificationCodeSent(true);

            // Show the verification code input
            setShowVerificationCodeInput(true);

            // Clear form errors if any
            setFormErrors({});
        }
        else {
            const checkLoginUrl = `http://api.quizzibee.com/api/check-login?msisdn=${encodeURIComponent(phoneNumber)}`;

            const checkMsisdnInput = () => !phoneNumber.trim();
            //const checkVerificationInputs = () => !verificationCode.trim();

            if (checkMsisdnInput()) {
                setFormErrors({
                    phoneNumber: 'Phone number is required.',
                    verificationCode: !verificationCode ? 'Verification code is required.' : '',
                });
            }
            else {
                fetch(checkLoginUrl)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.json();
                    })
                    .then(data => {
                        // setIsLogin(data.isLogin);
                        // console.log(data);

                        if (data.isLogin) {

                            const sendPinUrl = `http://api.quizzibee.com/api/sendpin?msisdn=${encodeURIComponent(phoneNumber)}`;

                            fetch(sendPinUrl)
                                .then(response => {
                                    if (!response.ok) {
                                        throw new Error('Network response was not ok');
                                    }
                                    return response.json();
                                })
                                .then(data => {
                                    // setIsLogin(data.isLogin);

                                    if (data) {
                                        // console.log(data);
                                        setPinData(data.pin);
                                        setLogPin(true);
                                        const isPinSent = true;
                                        // Set a message indicating that the verification code has been sent
                                        setVerificationCodeSent(true);

                                        // Show the verification code input
                                        setShowVerificationCodeInput(true);

                                        // Clear form errors if any
                                        setFormErrors({});
                                    } else {
                                        setFormErrors({
                                            phoneNumber: 'Invalid Phone number!',
                                        });


                                    }
                                })

                        }
                        else {
                            setFormErrors({
                                phoneNumber: 'Invalid Phone number!',
                            });
                            navigate('/subscribe');
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            }
        }

    };

    return (
        <>
            <Modal className="modal fade" show={show} onHide={onHide} backdrop="static" keyboard={false} style={{ zIndex: '99999' }}>
                <Modal.Header className="modal-header" closeButton>
                    <Modal.Title className="modal-title">Register With</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <Tabs className="tab-content" id="login-tab">
                        <Tab className="tab-pane fade show active" eventKey="login-phone">
                            <Form id="loginWithPhoneNo" onSubmit={handleLogin}>
                                <Form.Group controlId="phoneNumberInput">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        type='number'
                                        placeholder="Enter Phone Number"
                                        value={phoneNumber}
                                        defaultValue={+92}
                                        aria-label={'+92'}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        isInvalid={!!formErrors.phoneNumber}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formErrors.phoneNumber}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {showVerificationCodeInput && (
                                    <Form.Group controlId="vcField">
                                        <Form.Label>Verification Code</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter Verification Code"
                                            value={verificationCode}
                                            onChange={(e) => setVerificationCode(e.target.value)}
                                            isInvalid={!!formErrors.verificationCode}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formErrors.verificationCode}
                                        </Form.Control.Feedback>
                                        {verificationCodeSent && (
                                            <Form.Text className="text-muted" style={{ padding: '10px' }}>
                                                Verification code has been sent to you via SMS.
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                )}
                                {!verificationCodeSent && (
                                    <div className="login-wrapper" style={{ marginTop: '10px' }}>
                                        <button
                                            className="btn-nav btn-action"
                                            id="sendPinButton"
                                            onClick={handleSendVerificationCode}
                                        >
                                            Send Verification Code
                                        </button>
                                    </div>
                                )}
                                {showVerificationCodeInput && (
                                    <div className="login-wrapper" style={{ marginTop: '10px' }}>
                                        <button
                                            // type="submit" // This trigger the form submission
                                            className="btn-nav btn-action"
                                            id="loginPinButton"
                                        >
                                            Login
                                        </button>
                                    </div>
                                )}
                            </Form>
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
            <div id="app-message-box" />
        </>
    );
};

export default LoginModal;
