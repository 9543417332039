import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import './Game.css';
import ISLAMICTRIVIA from './images/BEE.png';
import { useNavigate, useParams } from 'react-router-dom';
import SPORTSTRIVIA from './images/bee-sport.png';
import SHOWBIZTRIVIA from './images/bee-shwbiz.png';
import ENGLISHTRIVIA from './images/english.png';
import MATHTRIVIA from './images/math.png';
import GENERALKNOWLEDGETRIVIA from './images/gk.png';
import PHYSICSTRIVIA from './images/phy.png';
import BIOLOGYTRIVIA from './images/bio.png';
import CHEMISTRYTRIVIA from './images/chem.png';

const TriviaGame = () => {

  const navigate = useNavigate();
  const { category } = useParams();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [incorrectAnswers, setIncorrectAnswers] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [timeLeft, setTimeLeft] = useState(30);
  const [quizData, setQuizData] = useState([]);
  const [answeredQuestions, setAnsweredQuestions] = useState(0);
  const [startTime, setStartTime] = useState(null); // State to hold start time
  const [endTime, setEndTime] = useState(null); // State to hold end time

  let quiztype;
  let quizName;
  let numberOfTimesSaved = 0;
  let imageName;

  if (category === 'islamic-trivia') {
    quiztype = 3;
    quizName = "ISLAMIC TRIVIA";
    imageName = ISLAMICTRIVIA;
  }
  else if (category === 'sports') {
    quiztype = 4;
    quizName = "SPORTS TRIVIA";
    imageName = SPORTSTRIVIA;
  }
  else if (category === 'showbiz') {
    quiztype = 5;
    quizName = "SHOWBIZ TRIVIA";
    imageName = SHOWBIZTRIVIA;
  }
  else if (category === 'english') {
    quiztype = 7;
    quizName = "ENGLISH TRIVIA";
    imageName = ENGLISHTRIVIA;
  }
  else if (category === 'math') {
    quiztype = 6;
    quizName = "MATH TRIVIA";
    imageName = MATHTRIVIA;
  }
  else if (category === 'general-knowledge') {
    quiztype = 8;
    quizName = "GENERAL KNOWLEDGE TRIVIA";
    imageName = GENERALKNOWLEDGETRIVIA;
  }
  else if (category === 'physics') {
    quiztype = 9;
    quizName = "PHYSICS TRIVIA";
    imageName = PHYSICSTRIVIA;
  }
  else if (category === 'biology') {
    quiztype = 11;
    quizName = "BIOLOGY TRIVIA";
    imageName = BIOLOGYTRIVIA;
  }
  else if (category === 'chemistry') {
    quiztype = 10;
    quizName = "CHEMISTRY TRIVIA";
    imageName = CHEMISTRYTRIVIA;
  }


  useEffect(() => {
    const fetchQuizData = async () => {
      const mymsisdn = sessionStorage.getItem("currentMsisdn");
      const cleanedMsisdn = mymsisdn.replace(/^"(.*)"$/, '$1');

      console.log(category);

      try {
        const response = await fetch(`http://api.quizzibee.com/api/get-islamic-quiz-questions?msisdn=${encodeURIComponent(cleanedMsisdn)}&cat=${encodeURIComponent(quiztype)}`);
        const data = await response.json();
        setQuizData(data);
        // console.log(data);
        // console.log(quizData);
        setStartTime(new Date()); // Set start time when quiz data is fetched
      } catch (error) {
        console.error('Error fetching quiz data:', error);
      }
    };

    fetchQuizData();
  }, []);

  const formatTime = (time) => {
    return time.toLocaleTimeString('en-US', { hour12: false });
  };


  useEffect(() => {
    const timer = setInterval(() => {
      if (timeLeft === 0) {
        clearInterval(timer);
        setEndTime(new Date()); // Set end time when timer reaches 0
        setShowResult(true);
      } else {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const handleAnswerSubmit = () => {
    // Check if an option is selected
    if (!selectedOption) {
      return; // Don't proceed if no option is selected
    }

    const correctAnswer = quizData[currentQuestion].answer;
    const submittedAnswer = selectedOption;

    // Check if the submitted answer is correct
    const isCorrect = submittedAnswer === correctAnswer;

    // Update score based on the correctness of the answer
    if (isCorrect) {
      setScore(prevScore => prevScore + 1);
    } else {
      // Track incorrect answers with the correct answer
      setIncorrectAnswers(prevIncorrectAnswers => [
        ...prevIncorrectAnswers,
        { question: quizData[currentQuestion].question, incorrectAnswer: submittedAnswer, correctAnswer: correctAnswer }
      ]);
    }

    setAnsweredQuestions(answeredQuestions + 1);

    // Move to the next question or show the result if all questions are answered
    if (currentQuestion === quizData.length - 1) {
      setShowResult(true);
      setTimeLeft(0); // Set the timer to zero when all questions are answered
      setEndTime(new Date()); // Set end time when all questions are answered
    } else {
      setCurrentQuestion(prevQuestion => prevQuestion + 1);
    }

    // Reset selected option after submitting answer
    setSelectedOption(null);
  };

  const handleRetry = () => {
    // setCurrentQuestion(0);
    // setScore(0);
    // setShowResult(false);
    // setIncorrectAnswers([]);
    // setTimeLeft(30);
    navigate('/');
  };

  // Function to hit the API and save trivia score
  const saveTriviaScore = async () => {
    const mymsisdn = sessionStorage.getItem("currentMsisdn");
    numberOfTimesSaved++;
    const cleanedMsisdn = mymsisdn.replace(/^"(.*)"$/, '$1');
    const cat_id = 3; // Assuming category ID is 3
    const obt_score = score;
    const total_score = quizData.length;
    const start_time = formatTime(startTime); // Format start time
    const end_time = formatTime(endTime); // Format end time

    const timeup_answered = answeredQuestions;

    if (numberOfTimesSaved === 1) {
      try {
        const response = await fetch(`http://api.quizzibee.com/api/save-trivia-score?msisdn=${encodeURIComponent(cleanedMsisdn)}&cat_id=${encodeURIComponent(quiztype)}&obt_score=${encodeURIComponent(obt_score)}&total_score=${encodeURIComponent(total_score)}&start_time=${encodeURIComponent(start_time)}&end_time=${encodeURIComponent(end_time)}&timeup_answered=${encodeURIComponent(timeup_answered)}`);
        if (response.ok) {
          // console.log('Trivia score saved successfully');
        } else {
          console.error('Failed to save trivia score');
        }
      } catch (error) {
        console.error('Error saving trivia score:', error);
      }
    }


  };

  useEffect(() => {
    if (showResult && endTime !== null) {
      saveTriviaScore(); // Call saveTriviaScore function when showResult is true and endTime is set
    }
  }, [showResult, endTime]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', background: '#e9c525' }}>
      <Navbar />
      <div className='Hangman-Card' style={{ marginTop: '40px' }}>
        <div className='Hangman'>
          <div className="Hangman-Box">

            <img id="islamic-trivia-image" src={imageName}></img>
            <h1>{quizName}</h1>
            <div id="timer">Total questions: <b>{quizData.length}</b></div>
            {!showResult && currentQuestion < quizData.length && (
              <div id="timer">Time will end in <b>{timeLeft}</b> seconds</div>
            )}
          </div>
          {!showResult && currentQuestion < quizData.length ? (
            <div id="Hangman-info">
              <div className="Hangman-hint">{quizData[currentQuestion].question}</div>
              <div className="options">
                {quizData[currentQuestion].options.map((option, index) => (
                  <div key={index} className="option" style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <input
                      type="radio"
                      name="quiz"
                      value={option}
                      checked={selectedOption === option}
                      onChange={() => setSelectedOption(option)}
                      style={{ transform: 'scale(1.3)', marginTop: '5px', marginLeft: '2px' }}
                    /><label style={{ overflowWrap: 'break-word', textWrap: 'wrap' }}>{option}</label>

                  </div>
                ))}
              </div>
              <a
                className={`btn-action ${!selectedOption && 'disabled'}`} // Apply 'disabled' class if no option is selected
                onClick={handleAnswerSubmit}
              >
                Submit
              </a>
            </div>
          ) : (
            <div id="result">
              <p>You scored {score} out of {quizData.length}!</p>
              <p>You answered {answeredQuestions} out of {quizData.length}!</p>
              {incorrectAnswers.length > 0 && (
                <div>
                  <p>Incorrect answers:</p>
                  <ul>
                    {incorrectAnswers.map((incorrectAnswer, index) => (
                      <li key={index}>{`${incorrectAnswer.question}:`} <b>{`${incorrectAnswer.correctAnswer}`}</b></li>
                    ))}
                  </ul>
                </div>
              )}
              <a id="retry" className={`btn-nav ${showResult ? '' : 'hide'}`} onClick={handleRetry}>Back to Home Page</a>
            </div>
          )}
        </div>

      </div>
    </div>
  );
};

export default TriviaGame;

