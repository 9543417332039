import React from 'react';
import Navbar from '../Navbar'; // Import your Navbar component
import Footer from '../Footer'; // Import your Footer component
import '../App.css';
const Faq = () => {
  return (
    <div className="wrapper">
      <Navbar />
      <div id="main" className="main-content">
        <div id="tnc" className="tnc">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12">
                <div className="hero-content wow fadeIn">
                  <div className="text-content">
                    <h1>Frequently Asked Questions</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="faq-wrapper" className="faq-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="content">
                <ol>
                                    <li>What is Quizzibee?</li>
                                    <ol>
                                        <li>Quizzibee is an engaging online platform that offers a word-guessing game for subscribers. Subscribers can enjoy up to three plays per day, enhancing the gaming experience. Quizzibee recognizes and rewards the top 250 players weekly, fostering healthy competition and providing incentives for active participation. Join Quizzibee for a daily dose of word-guessing fun and a chance to showcase your skills among the top players!</li>
                                    </ol>
                                    <li>How do I subscribe?</li>
                                    <ol>
                                        <li>You may click the subscribe button available on the portal. Do note that you can only subscribe to Quizzibee through your mobile data.</li>
                                    </ol>
                                    <li>What comes with my subscription?</li>
                                    <ol>
                                        <li>
                                            Quizzibee comes with super fun and exciting features that stimulate users’ brains! Features include playing games, be a top player and get rewards. These are all part of Quizzibee library of quality content for you to enjoy.
                                        </li>
                                    </ol>
                                    <li>What devices are compatible to access this portal?</li>
                                    <ol>
                                        <li>It can be used on any mobile device with minimum OS such as iOS devices version 3.0+ or Android devices version 2.0+</li>
                                    </ol>
                                    <li>How do I terminate my subscription</li>
                                    <ol>
                                        <li>You can terminate your subscription by</li>
                                    </ol>
                                    <li>How much is a subscription to Quizzibee?</li>
                                    <ol>
                                        <li>The price for this service will be</li>
                                    </ol>
                                    <li>Do I need an internet connection to use Quzzibee?</li>
                                    <ol>
                                        <li>Yes</li>
                                    </ol>
                                    <li>Help! How do I contact you for additional assistance?</li>
                                    <ol>
                                        <li>You may send us an email at support@ps.quizzibee.com</li>
                                    </ol>
                                    <li>Can I play Quizzibee on multiple devices</li>
                                    <ol>
                                        <li>Yes, Quizzibee can be played on Apple, Android and PC.</li>
                                    </ol>
                                </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Faq;
