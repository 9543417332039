import React, { useState, useEffect } from "react";
import "./Game.css";
import img0 from "./images/0.png";
import img1 from "./images/1.png";
import img2 from "./images/2.png";
import img3 from "./images/3.png";
import img4 from "./images/4.png";
import img5 from "./images/5.png";
import lost from './images/lost.gif';
import victory from './images/victory.gif';
import Navbar from "./Navbar";
import MessageBox from "./MessageBox";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
const handleButton = async () => {
    const result = await MessageBox.open({
        content: <p>"This will be your last attempt of the day! Come back tomorrow to become eligible for weekly rewards. You'll be redirected to Home after this attempt!"</p>,
        buttons: [{ name: "Ok", handler: () => "Ok" }]
    });
};


const Game = () => {
    const navigate = useNavigate();
    const [nWrong, setNWrong] = useState(0);
    const [guessed, setGuessed] = useState(new Set());
    const [showModal, setShowModal] = useState(false);
    const [wordsList, setWordsList] = useState([]);
    const [wordsListLoaded, setWordsListLoaded] = useState(false);
    const [apiError, setApiError] = useState(null);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [gameResult, setGameResult] = useState(null);
    const [playCount, setPlayCount] = useState(0);
    const [answer, setAnswer] = useState("");
    const [hint, setHint] = useState("");
    const [images] = useState([img0, img1, img2, img3, img4, img5]);
    const [maxWrong] = useState(5);
    const [duration] = useState(30000); // 30 seconds
    const [timerID, setTimerID] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [gameStarted, setGameStarted] = useState(false); // Step 1
    const [correctGuessCount, setCorrectGuessCount] = useState(0); // Track correct guess count
    var leavingPage = "";

    const wantToGoBack = async () => {
        const result = await MessageBox.open({
            content: <p>"Do you really want to go back? If you leave this page you'll lose the game!"</p>,
            buttons: [{ name: "No", handler: () => "No" }, {
                name: "Yes", handler: () => {
                    const endTime = new Date();
                    const formattedEndTime = `${endTime.getHours().toString().padStart(2, '0')}:${endTime.getMinutes().toString().padStart(2, '0')}:${endTime.getSeconds().toString().padStart(2, '0')}`;
                    // console.log("END TIME", formattedEndTime);
                    setEndTime(formattedEndTime);
                    leavingPage = "yes";

                    // submitGameResult();
                    // navigate('/');
                    resetGameAndCloseModal(false);
                }
            }]
        });
    };
    useEffect(() => {

        fetchWords();
    }, []);

    useEffect(() => {

        canPlayNow();
    }, [playCount]);

    useEffect(() => {
        if (playCount === 3) {
            handleButton();
        }
    }, [playCount]);

    const fetchWords = async () => {
        const mymsisdn = sessionStorage.getItem("currentMsisdn");

        if (mymsisdn === null) {
            navigate('/');
        }
        else {

            const cleanedMsisdn = mymsisdn.replace(/^"(.*)"$/, '$1');

            const sendPinUrl = `http://api.quizzibee.com/api/get-words?top=${encodeURIComponent(1)}&msisdn=${encodeURIComponent(cleanedMsisdn)}`;
            try {
                const response = await fetch(sendPinUrl);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();

                if (data) {
                    const randomIndex = Math.floor(Math.random() * data.length);
                    const randomWord = data[randomIndex];
                    const word = { word: randomWord.words, hint: randomWord.hint };
                    // console.log("data", data);
                    // console.log("index", data[randomIndex]);
                    // console.log("word", word);
                    setWordsList(data[randomIndex]);
                    setAnswer(randomWord.words.toLowerCase());
                    setHint(randomWord.hint);
                    setWordsListLoaded(true);
                } else {
                    throw new Error('Empty response received');
                }
            } catch (error) {
                console.error("Error fetching words:", error);
                setApiError(error.message);
            }
        }
    };

    const canPlayNow = async () => {
        const mymsisdn = sessionStorage.getItem("currentMsisdn");
        if (mymsisdn === null) {
            navigate('/');
        }
        else {

            const cleanedMsisdn = mymsisdn.replace(/^"(.*)"$/, '$1');
            const sendPinUrl = `http://api.quizzibee.com/api/can-play?msisdn=${encodeURIComponent(cleanedMsisdn)}`;
            try {
                const response = await fetch(sendPinUrl);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                if (data) {
                    // console.log(data);
                    setPlayCount(data.play_count + 1);
                    // console.log("PLAY COUNT: ", playCount);
                } else {
                    throw new Error('Empty response received');
                }
            } catch (error) {
                console.error("Error fetching words:", error);
            }
        }
    };

    const submitGameResult = async () => {
        const mymsisdn = sessionStorage.getItem("currentMsisdn");
        const cleanedMsisdn = mymsisdn.replace(/^"(.*)"$/, '$1');
        var score;
        if (gameResult === "won") {
            score = 1;
        } else if (gameResult === "lost") {
            score = 0;
        } else {
            if (leavingPage === "yes") {
                score = 0;
            }
        }
        // console.log("before api settlement: ", cleanedMsisdn, score, wordsList.id, correctGuessCount, answer.length);

        var sendPinUrl;
        if (leavingPage === "yes") {
            const endTime = new Date();
            const formattedEndTime = `${endTime.getHours().toString().padStart(2, '0')}:${endTime.getMinutes().toString().padStart(2, '0')}:${endTime.getSeconds().toString().padStart(2, '0')}`;

            sendPinUrl = `http://api.quizzibee.com/api/save-score?msisdn=${encodeURIComponent(cleanedMsisdn)}&score=${encodeURIComponent(score)}&word_id=${encodeURIComponent(wordsList.id)}&guessed_letters=${encodeURIComponent(correctGuessCount)}&challenge_no=${encodeURIComponent(playCount)}&total_letters=${encodeURIComponent(answer.length)}&start_time=${encodeURIComponent(startTime)}&end_time=${encodeURIComponent(formattedEndTime)}`;
        }
        else {
            sendPinUrl = `http://api.quizzibee.com/api/save-score?msisdn=${encodeURIComponent(cleanedMsisdn)}&score=${encodeURIComponent(score)}&word_id=${encodeURIComponent(wordsList.id)}&guessed_letters=${encodeURIComponent(correctGuessCount)}&challenge_no=${encodeURIComponent(playCount)}&total_letters=${encodeURIComponent(answer.length)}&start_time=${encodeURIComponent(startTime)}&end_time=${encodeURIComponent(endTime)}`;
        }
        try {
            const response = await fetch(sendPinUrl);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();

            if (data) {
                // console.log("score saved: ", data);
            } else {
                throw new Error('no score saved');
            }
        } catch (error) {
            console.error("Error saving score:", error);
            setApiError(error.message);
        }
    };

    useEffect(() => {
        if (wordsListLoaded) {
            const startTime = new Date();
            const formattedStartTime = `${startTime.getHours().toString().padStart(2, '0')}:${startTime.getMinutes().toString().padStart(2, '0')}:${startTime.getSeconds().toString().padStart(2, '0')}`;
            // console.log("START TIME", formattedStartTime);
            setStartTime(formattedStartTime);
            setGameStarted(true); // Step 2
            startTimer();
        }
    }, [wordsListLoaded]);

    useEffect(() => {
        let count = 0;
        for (const letter of answer) {
            if (guessed.has(letter)) {
                count++;
            }
        }
        setCorrectGuessCount(count);
    }, [guessed]);

    useEffect(() => {
        if (gameStarted) { // Step 3
            const endGame = autoEndGame(nWrong, answer, guessed, elapsedTime, duration, showModal, gameWon, gameLost, gameStarted);
            if (endGame.showModal) {
                const endTime = new Date();
                const formattedEndTime = `${endTime.getHours().toString().padStart(2, '0')}:${endTime.getMinutes().toString().padStart(2, '0')}:${endTime.getSeconds().toString().padStart(2, '0')}`;
                // console.log("END TIME", formattedEndTime);
                setEndTime(formattedEndTime);
                setShowModal(true);
                setGameResult(endGame.gameResult);
            }
        }
    }, [nWrong, elapsedTime]);

    useEffect(() => {
        if (gameResult === "lost" || gameResult === "won") {
            //...
            clearInterval(timerID);
        }
    }, [gameResult]);

    useEffect(() => {
        if (gameStarted) {
            if (gameWon(answer, guessed)) {
                setGameResult("won");
            } else if (gameLost()) {
                setGameResult("lost");
            }
        }
    }, [nWrong, elapsedTime]);

    const guessedWord = () => {
        return answer.split("").map((ltr, index) => (
            <li key={index} className={guessed.has(ltr) ? 'visible' : 'hidden'}>
                {guessed.has(ltr) ? ltr : ""}
            </li>
        ));
    };

    const startTimer = () => {
        const id = setInterval(() => {
            setElapsedTime(prevElapsedTime => prevElapsedTime + 1000);
        }, 1000);
        setTimerID(id);
        const startTime = new Date();
        const formattedStartTime = `${startTime.getHours().toString().padStart(2, '0')}:${startTime.getMinutes().toString().padStart(2, '0')}:${startTime.getSeconds().toString().padStart(2, '0')}`;
        // console.log("START TIME", formattedStartTime);
        setStartTime(formattedStartTime);
    };

    const resetTimer = () => {
        clearInterval(timerID);
        setElapsedTime(0);
    };

    const formatTime = (milliseconds) => {
        const seconds = Math.floor(milliseconds / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const formattedHours = padZero(hours);
        const formattedMinutes = padZero(minutes % 60);
        const formattedSeconds = padZero(seconds % 60);
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };

    const padZero = (number) => {
        return number < 10 ? `0${number}` : `${number}`;
    };

    const generateButtons = () => {
        return "abcdefghijklmnopqrstuvwxyz".split("").map((ltr, index) => (
            <button
                key={index}
                value={ltr}
                onClick={() => handleGuess(ltr)}
                disabled={guessed.has(ltr)}
            >
                {ltr}
            </button>
        ));
    };

    const autoEndGame = (nWrong, answer, guessed, elapsedTime, duration, showModal, gameWon, gameLost) => {
        if (elapsedTime >= duration && !showModal) {
            // Check if the game is won or lost
            if (gameWon(answer, guessed)) {
                return { showModal: true, gameResult: "won" };
            } else {
                return { showModal: true, gameResult: "lost" };
            }
        } else {
            const isGameLost = gameLost(nWrong, duration);
            if (gameWon(answer, guessed)) {
                return { showModal: true, gameResult: "won" };
            } else {
                if (isGameLost) {
                    return { showModal: true, gameResult: "lost" };
                }
            }
        }
        return {};
    };


    const updateTimer = () => {
        const id = setInterval(() => {
            setElapsedTime(prevElapsedTime => prevElapsedTime + 1000);
        }, 1000);
        setTimerID(id);

        if (showModal) {
            clearInterval(id);
        } else {
            const endGame = autoEndGame(nWrong, answer, guessed, elapsedTime, duration, showModal, gameWon, gameLost);
            if (endGame.showModal) {
                clearInterval(id);
                return endGame;
            }
        }
        return {};
    };

    const resetGame = () => {
        resetTimer();
        setNWrong(0);
        setPlayCount(playCount + 1);
        setGuessed(new Set());
        setShowModal(false);
        fetchWords();
        startTimer();
    };

    const resetGameAndCloseModal = (playagain) => {

        if (playagain) {
            submitGameResult();

            if (playCount <= 3) {
                if (playCount === 2) {
                    handleButton();
                }
                resetGame();
                setShowModal(false);

            }
            if (playCount === 3) {
                // console.log("out of the box");
                navigate('/');
            }
        }
        else {
            submitGameResult();

            if (playCount <= 3) {
                if (playCount === 2) {
                    handleButton();
                }
                setShowModal(false);

            }
            if (playCount === 3) {
                // console.log("out of the box");
                navigate('/');
            }

            navigate('/');
        }
    };

    const handleGuess = (ltr) => {
        setGuessed(prevGuessed => new Set(prevGuessed.add(ltr)));
        setNWrong(prevNWrong => prevNWrong + (answer.includes(ltr) ? 0 : 1));
    };

    const gameWon = (answer, guessed) => {
        const isWordGuessed = [...new Set(answer)].every(char => guessed.has(char));
        return isWordGuessed;
    };

    const gameLost = () => {
        return nWrong >= maxWrong || elapsedTime >= duration;
    };

    const formattedTime = formatTime(elapsedTime);

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', background: '#e9c525' }}>
                <Navbar wantToGoBack={wantToGoBack} />

                <div className='Hangman-Card' style={{ marginTop: '40px' }}>
                    <div className='Hangman'>
                        <div className="Hangman-Box">
                            <img src={images[nWrong]} alt="sample" />
                            <h1>Hangman Game</h1>
                            <div><h2 style={{ fontSize: '13px', color: 'red' }}>You only have 30 seconds to play this game.</h2></div>
                            <div id="timer">{formattedTime}</div>
                        </div>
                        <div className='Hangman-info'>
                            <h4>Incorrect guesses: <b className="IncorrectCounter">{nWrong}/5</b></h4>

                            {showModal && (
                                <div className={`game-modal ${showModal ? 'show' : ''}`}>
                                    <div className="content">
                                        {gameResult === "won" ? (
                                            <>
                                                <img src={victory} alt="gif"></img>
                                                <h4>Congrats! You got 1 score.</h4>
                                                <p>You found the word: <b>{answer}</b></p>
                                            </>
                                        ) : (
                                            <>
                                                <img src={lost} alt="gif"></img>
                                                <h4>Game Over! You got 0 score.</h4>
                                                <p>The correct word was: <b>{answer}</b></p>
                                            </>
                                        )}

                                        <a className="btn-nav" onClick={() => resetGameAndCloseModal(true)}>Play Again</a>
                                        <a className="btn-nav" style={{ marginLeft: '10px' }} onClick={() => resetGameAndCloseModal(false)}>Back to Home</a>
                                    </div>
                                </div>
                            )}

                            {!showModal && (
                                <>
                                    <ul className="word-display">
                                        {guessedWord()}
                                    </ul>
                                    <p className='Hangman-hint'>Hint: <b>{hint}</b></p>
                                    <p className='Hangman-btns'>{generateButtons()}</p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Game;
