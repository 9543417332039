import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/Home';
import Captcha from './components/Captcha';
import Faq from './components/Faq';
import Game from './Game';
import Profile from './components/Profile';
import LeaderBoard from './components/LeaderBoard';
import Contact from './components/Contact';
import Tnc from './components/Tnc';
import Subscribe from './components/Subscribe';
import NormalGame from './NormalGame';
import TriviaGame from './TriviaGame';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import './App.css';
export default function App() {

  return (
    <>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route path='/captcha' element={<Captcha />} />
        <Route path='/faq' element={<Faq />} />
        <Route path='/user-profile' element={<Profile />} />
        <Route path='/terms-n-conditions' element={<Tnc />} />
        <Route path='/game' element={<Game />} />
        <Route path='/hangman-game' element={<NormalGame />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/subscribe' element={<Subscribe />} />
        <Route path='/leaderboard' element={<LeaderBoard />} />
        <Route path='/trivia-game/:category' element={<TriviaGame />} />
      </Routes>
    </>
  );
}

