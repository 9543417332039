import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import '../App.css';
import EditProfileModal from './EditProfileModal';

const Profile = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [userScores, setUserScores] = useState([]);
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');

    useEffect(() => {
        const footerMenuContainer = document.getElementById('footerMenuContainer');
        if (footerMenuContainer) {
            const unsubscribeListItem = document.createElement('li');
            unsubscribeListItem.innerHTML = '<a href="/Home/UserProfile.html">Unsubscribe</a>';
            const profileListItem = document.createElement('li');
            profileListItem.innerHTML = '<a href="/Home/UserProfile.html">My Profile</a>';
            footerMenuContainer.querySelector('.footer-menu').appendChild(unsubscribeListItem);
            footerMenuContainer.querySelector('.footer-menu').appendChild(profileListItem);
        }
        fetchUserData();
        fetchScores();
    }, []);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const hideModal = () => {
        setIsModalVisible(false);
    };

    const fetchScores = async () => {
        const mymsisdn = sessionStorage.getItem("currentMsisdn");
        const cleanedMsisdn = mymsisdn.replace(/^"(.*)"$/, '$1');
        // console.log(cleanedMsisdn);
        const sendPinUrl = `http://api.quizzibee.com/api/get-user-scores?msisdn=${encodeURIComponent(cleanedMsisdn)}`;
        try {
            const response = await fetch(sendPinUrl);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();

            if (data) {
                // console.log("data", data);
                setUserScores(data);
            } else {
                throw new Error('Empty response received');
            }
        } catch (error) {
            console.error("Error fetching words:", error);
        }
    };

    const fetchUserData = async () => {
        const mymsisdn = sessionStorage.getItem("currentMsisdn");
        const cleanedMsisdn = mymsisdn.replace(/^"(.*)"$/, '$1');
        // console.log(cleanedMsisdn);
        const sendPinUrl = `http://api.quizzibee.com/api/get-data-for-profile?msisdn=${encodeURIComponent(cleanedMsisdn)}`;
        try {
            const response = await fetch(sendPinUrl);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();

            if (data) {
                setUserName(data.name);
                setUserEmail(data.email);
            } else {
                throw new Error('Empty response received');
            }
        } catch (error) {
            console.error("Error fetching words:", error);
        }
    };

    const filterTable = (event) => {
        const filter = event.target.value.toUpperCase();
        const table = document.getElementById("myTable");
        const rows = table.getElementsByTagName("tr");
        for (let i = 0; i < rows.length; i++) {
            const td = rows[i].getElementsByTagName("td")[0];
            if (td) {
                const txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    rows[i].style.display = "";
                } else {
                    rows[i].style.display = "none";
                }
            }
        }
    };

    return (
        <>
            <div className="wrapper">
                <Navbar />
                <div id="main" className="main-content">
                    <div id="tnc" className="tnc">
                        <div className="container">
                            <div className="row text-center">
                                <div className="col-md-12">
                                    <div className="hero-content wow fadeIn">
                                        <div className="text-content">
                                            <h1>Profile</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="contact-wrapper" className="contact-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="contact-content">
                                        <div className="contact-desc-title">
                                            <h1>Profile Details</h1>
                                        </div>
                                        <div className="contact-detail">
                                            <ul>
                                                {userName && userEmail ? (
                                                    <>
                                                        <li>
                                                            <i className="fas fa-user"></i>
                                                            <a>{userName}</a>
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-envelope"></i>
                                                            <a href="#">{userEmail}</a>
                                                        </li>
                                                    </>
                                                ) : (
                                                    <h4>Set your profile and play daily challenges to find yourself in the weekly scoreboard.</h4>
                                                )}
                                            </ul>
                                        </div>
                                        <div className="login-wrapper">
                                            <button className="btn-nav" onClick={showModal} style={{ color: 'white' }}>Edit Profile</button>
                                        </div>

                                        <div className="login-wrapper">
                                            <button className="btn-nav" onClick={showModal} style={{ color: 'white' , background:'linear-gradient(90deg, #fab41f 0%, #f7cc3d 100%)', marginTop: '15px'}}>Unsubscribe</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* History Table Start */}
                    <div id="main" className="main-content">
                        <div id="contact-wrapper" className="contact-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="contact-content">
                                            <div className="contact-desc-title">
                                                <h1 style={{ display: 'flex', marginBottom: '10px' }}>Score History</h1>
                                            </div>
                                            <div className="search-container">
                                                <i className="fa fa-search"></i>
                                                <input type="text" id="myInput" onKeyUp={filterTable} placeholder="Search for date.." title="Type in a date" />
                                            </div>                                            
                                            <table id="myTable" className="table table-striped table-responsive">
                                                <thead>
                                                    <tr className="header">
                                                        <th style={{ width: '15%' }}>Date</th>
                                                        <th style={{ width: '20%' }}>Challenge No. (Per Date)</th>
                                                        <th style={{ width: '10%' }}>Score</th>
                                                        <th style={{ width: '15%' }}>Number of Letters</th>
                                                        <th style={{ width: '25%' }}>Guessed letters before Time-up</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {userScores.length > 0 ? (
                                                        userScores.map((score, index) => (
                                                            <tr key={index}>
                                                                <td>{score.date_created}</td>
                                                                <td>{score.challenge_no}</td>
                                                                <td>{score.score}</td>
                                                                <td>{score.total_letters}</td>
                                                                <td>{score.guessed_letters}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="5" style={{ textAlign: 'center' }}>No games played yet</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* History Table End */}
                    <Footer />
                </div>
            </div>
            <EditProfileModal show={isModalVisible} onHide={hideModal} />
        </>
    );
};

export default Profile;
