
import Navbar from '../Navbar';
import Footer from '../Footer';
import phoneImage from '../images/Phone.png';
import featureTop3Image from '../images/feature-top-3.png';
import beeImage from '../images/BEE.png';
import featureTop1Image from '../images/feature-top-1.png';
import feature1Image from '../images/feature-1.png';
import feature2Image from '../images/feature-2.png';
import feature3Image from '../images/feature-3.png';
import feature4Image from '../images/feature-4.png';
import feature5Image from '../images/feature-5.png';
import feature6Image from '../images/feature-6.png';
import rightBannerImage from '../images/right-banner.png';
import leftBannerImage from '../images/left-banner.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import MessageBox from '../MessageBox';
import Bee from '../images/book.png';
import ShowbizBee from '../images/bee-shwbiz.png';
import SportsBee from '../images/bee-sport.png';
import ENGLISHTRIVIA from '../images/english.png';
import MATHTRIVIA from '../images/math.png';
import GENERALKNOWLEDGETRIVIA from '../images/gk.png';
import PHYSICSTRIVIA from '../images/phy.png';
import BIOLOGYTRIVIA from '../images/bio.png';
import CHEMISTRYTRIVIA from '../images/chem.png';

const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,

};
const Home = () => {
    var messageInBox = "";
    var customButtons = [];
    var title = "";
    const navigate = useNavigate();
    var canplay;

    const canPlayNow = async () => {
        const mymsisdn = sessionStorage.getItem("currentMsisdn");
        const cleanedMsisdn = mymsisdn.replace(/^"(.*)"$/, '$1');
        const sendPinUrl = `http://api.quizzibee.com/api/can-play?msisdn=${encodeURIComponent(cleanedMsisdn)}`;
        try {
            const response = await fetch(sendPinUrl);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data) {
                // console.log(data);
                // console.log(data.play_count);
                if (data.play_count < 3) {
                    canplay = true;
                }
                else {
                    canplay = false;
                }

                const loggedInUser = sessionStorage.getItem('currentMsisdn');
                if (loggedInUser && canplay) {
                    // Allow navigation if the user is logged in
                    navigate('/game');
                } else if (!canplay) {
                    // Console log 'Not allowed' if the user is not logged in
                    messageInBox = "You have used your 3 tries of the day! Come back tomorrow to become eligible for weekly rewards.";
                    customButtons = [{ name: "Ok", handler: () => "Ok" }];
                    handleButton();
                }
            } else {
                throw new Error('Empty response received');
            }
        } catch (error) {
            console.error("Error fetching words:", error);
        }
    };

    const checkLoggedInUser = () => {

        const loggedInUser = sessionStorage.getItem('currentMsisdn');
        if (!loggedInUser) {
            // Console log 'Not allowed' if the user is not logged in
            messageInBox = "Log In first to enjoy the challenge!";
            customButtons = [{ name: "Ok", handler: () => "Ok" }];
            handleButton();
        } else {
            title = "Welcome Back!";
            messageInBox = "Select the game mode! Normal mode does not have timer you can play as much as you want, Challenge mode contains timer and can be played 3 times a day. Weekly rewards are only given on challenge mode.";
            customButtons = [{
                name: "Normal ", handler: () => {
                    navigate('/hangman-game');
                }
            },
            {
                name: "Challenge", handler: () => {
                    canPlayNow();
                }
            }
            ];
            handleButton();

        }
    };

    const checkLoggedInUserANDGotoTrivia = (type) => {

        const loggedInUser = sessionStorage.getItem('currentMsisdn');
        if (!loggedInUser) {
            // Console log 'Not allowed' if the user is not logged in
            messageInBox = "Log In first to enjoy the challenge!";
            customButtons = [{ name: "Ok", handler: () => "Ok" }];
            handleButton();
        } else {
            title = "Welcome Back!";
            messageInBox = "Are you ready fo this quiz? You will get 10 questions of the day, you have to solve them within 30 seconds.";
            customButtons = [{
                name: "Yes ", handler: () => {
                    navigate(`/trivia-game/${type}`);
                }
            },
            {
                name: "No", handler: () => {
                    "NO"
                }
            }
            ];
            handleButton();

        }
    };

    const handleButton = async () => {
        const result = await MessageBox.open({
            title: title,
            content: <p>{messageInBox}</p>,
            buttons: customButtons
        });
    };

    return (
        <>
            <div class="wrapper">
                {/* <!-- Navbar Section --> */}
                <div style={{ position: "static" }}>
                    <Navbar />
                </div>
                {/* <!-- Navbar End --> */}

                <div id="main" class="main-content">
                    <div id="home" class="home">
                        {/* <!-- Hero Section--> */}
                        <div class="container">
                            <div class="row text-center">
                                <div class="col-md-12">
                                    <div class="hero-content wow fadeIn">
                                        <img class="banner-left" src={leftBannerImage} alt=""></img>
                                        <div class="text-content">
                                            <h1>Play with QuizziBee</h1>
                                            <p>Top 250 players get weekly rewards. Play now!</p>
                                            <a class="btn-action" onClick={checkLoggedInUser} >Play Now</a>
                                        </div>
                                        <img class="banner-right" src={rightBannerImage} alt=""></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Hero End --> */}

                    <div id="about" class="about-wrapper">
                        <div class="about-box">
                            <div class="about-inner">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-7 col-xs-12">
                                            <h2>About Quizzibee</h2>
                                            <p>
                                                Step into the world of QuizziBee, where the classic Hangman game meets trivia
                                                excitement! Test your word-solving skills, guess the hidden phrases, and embrace
                                                the challenge. QuizziBee offers quick and engaging puzzles for a fun and
                                                educational experience. Ready to take on the wordy adventure? Let the guessing
                                                games begin!</p>
                                            <div class="start-btn">
                                                <a class="btn-nav" style={{ width: '250px', color: 'white' }} onClick={checkLoggedInUser}>Start Now</a>
                                            </div>
                                        </div>
                                        <div class="col-md-5 col-xs-12">
                                            <img src={phoneImage} alt=""></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="features" class="features-wrapper">
                        <div class="container">
                            <div class="row justify-center">
                                <div class="col-md-6">
                                    <div class="features-intro wow fadeInDown text-center">
                                        <h1>Easy start with Quizzibee</h1>
                                    </div>
                                </div>
                            </div>
                            <div class="card-wrapper">
                                <div class="card-single">
                                    <div class="card-icon">
                                        <div class="card-img">
                                            <img src={feature1Image} alt="Feature"></img>
                                        </div>
                                    </div>
                                    <div class="card-text" onClick={checkLoggedInUser}>
                                        <h3>QuizziBee Daily Challenge</h3>
                                        <p>Play the daily word Challenge of QuizziBee to get weekly rewards.You can play 3 times a day, you'll only get 30 seconds for each game. Click here to start the challenge.</p>
                                    </div>
                                </div>
                                <div class="card-single">
                                    <div class="card-icon">
                                        <div class="card-img">
                                            <img src={feature2Image} alt="Feature"></img>
                                        </div>
                                    </div>
                                    <div class="card-text" onClick={checkLoggedInUser}>
                                        <h3>Normal Mode</h3>
                                        <p>This game doesnot contain any timer so it will be easier for you to practice for daily challenges. Click here to start practicing.</p>
                                    </div>
                                </div>
                                <div class="card-single">
                                    <div class="card-icon">
                                        <div class="card-img">
                                            <img src={feature3Image} alt="Feature"></img>
                                        </div>
                                    </div>
                                    <div class="card-text">
                                        <h3>Weekly Rewards for Top Performers</h3>
                                        <p>QuizziBee encourages friendly competition by rewarding the top 250 users every week.</p>
                                    </div>
                                </div>
                                <div class="card-single">
                                    <div class="card-icon">
                                        <div class="card-img">
                                            <img src={feature4Image} alt="Feature"></img>
                                        </div>
                                    </div>
                                    <div class="card-text" onClick={() => checkLoggedInUserANDGotoTrivia('islamic-trivia')}>
                                        <h3>Islamic Trivia</h3>
                                        <p>Put your knowledge to the test with a variety of islamic questions &amp; quizzes.</p>
                                    </div>
                                </div>
                                <div class="card-single">
                                    <div class="card-icon">
                                        <div class="card-img">
                                            <img src={feature5Image} alt="Feature"></img>
                                        </div>
                                    </div>
                                    <div class="card-text">
                                        <h3>Progress Tracker</h3>
                                        <p>​Save your progress &amp; jump back in anytime</p>
                                    </div>
                                </div>
                                <div class="card-single">
                                    <div class="card-icon">
                                        <div class="card-img">
                                            <img src={feature6Image} alt="Feature"></img>
                                        </div>
                                    </div>
                                    <div class="card-text">
                                        <h3>Community Engagement</h3>
                                        <p>Fosters a sense of community by providing a platform to share achievements, compete
                                            with friends, and celebrate victories together.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div id="about" class="about-wrapper">
                        <div class="about-box">
                            <div class="about-inner">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-7 col-xs-12">
                                            <h2>QuizziBee's Islamic Trivia!</h2>
                                            <p>
                                                Test your knowledge of Islam by playing our islamic trivia; simply click the button to get started. Ten questions will be presented to you, and at the conclusion of the game, your knowledge will be evaluated. Play it as quickly as you can because you have only thirty seconds.
                                            </p>
                                            <div class="start-btn">
                                                <a class="btn-nav" style={{ width: '250px', color: 'white' }} onClick={() => checkLoggedInUserANDGotoTrivia('islamic-trivia')}>Play Now</a>
                                            </div>
                                        </div>
                                        <div class="col-md-5 col-xs-12">
                                            <img src={Bee} alt="" style={{ marginBottom: '20px' }}></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* sports trivia */}
                    <div id="sports" class="sports-wrapper">
                        <div class="sports-box">
                            <div class="sports-inner">
                                <div class="container">
                                    <div class="row">

                                        <div class="col-md-5 col-xs-12">
                                            <img src={SportsBee} alt="" style={{ marginBottom: '20px', marginLeft: '-30px' }}></img>
                                        </div>

                                        <div class="col-md-7 col-xs-12">
                                            <h2>QuizziBee's Sports Trivia!</h2>
                                            <p>
                                                Test your knowledge of Sports by playing our sport trivia; simply click the button to get started. Ten questions will be presented to you, and at the conclusion of the game, your knowledge will be evaluated. Play it as quickly as you can because you have only thirty seconds.
                                            </p>
                                            <div class="start-btn">
                                                <a class="btn-nav" style={{ width: '250px', color: 'white' }} onClick={() => checkLoggedInUserANDGotoTrivia('sports')}>Play Now</a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* showbiz trivia */}
                    <div id="showbiz" class="showbiz-wrapper">
                        <div class="showbiz-box">
                            <div class="showbiz-inner">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-7 col-xs-12">
                                            <h2>QuizziBee's ShowBiz Trivia!</h2>
                                            <p>
                                                Test your knowledge of Film and Drama Industries by playing our Showbiz trivia; simply click the button to get started. Ten questions will be presented to you, and at the conclusion of the game, your knowledge will be evaluated. Play it as quickly as you can because you have only thirty seconds.
                                            </p>
                                            <div class="start-btn">
                                                <a class="btn-nav" style={{ width: '250px', color: 'white' }} onClick={() => checkLoggedInUserANDGotoTrivia('showbiz')}>Play Now</a>
                                            </div>
                                        </div>
                                        <div class="col-md-5 col-xs-12">
                                            <img src={ShowbizBee} alt="" style={{ marginBottom: '20px' }}></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* EDUCATION trivia */}
                    <div id="sports" class="sports-wrapper">
                        <div class="sports-box" style={{ background: 'linear-gradient(90deg, #4f866d 0%, #19ec8df8 100%)' }}>
                            <div class="sports-inner">
                                <div class="container">
                                    <h2>QuizziBee's Educational Trivia!</h2>
                                    <div class="card-wrapper">
                                        <div class="card-single top" style={{ backgroundColor: 'transparent' }}>
                                            <div class="card-icon big">
                                                <div class="card-img">
                                                    <img src={ENGLISHTRIVIA} alt="Feature"></img>
                                                </div>
                                            </div>
                                            <div class="card-text">
                                                <h2 style={{ fontSize: '30px' }}>English Trivia!</h2>
                                                <p>Test your English skills by taking our quiz, you have to answer 10 questions in 30 seconds. Try it now.
                                                </p>
                                            </div>

                                            <div class="start-btn">
                                                <a class="btn-nav" style={{ width: '250px', color: 'white' }} onClick={() => checkLoggedInUserANDGotoTrivia('english')}>Play Now</a>
                                            </div>
                                        </div>
                                        {/* 2''''''' */}
                                        <div class="card-single top" style={{ backgroundColor: 'transparent' }}>
                                            <div class="card-icon big">
                                                <div class="card-img">
                                                    <img src={MATHTRIVIA} alt="Feature"></img>
                                                </div>
                                            </div>
                                            <div class="card-text">
                                                <h2 style={{ fontSize: '30px' }}>Mathematics Trivia!</h2>
                                                <p>Test your Mathematics skills by taking our quiz, you have to answer 10 questions in 30 seconds. Try it now.
                                                </p>
                                            </div>

                                            <div class="start-btn">
                                                <a class="btn-nav" style={{ width: '250px', color: 'white' }} onClick={() => checkLoggedInUserANDGotoTrivia('math')}>Play Now</a>
                                            </div>
                                        </div>
                                        {/* 3====== */}
                                        <div class="card-single top" style={{ backgroundColor: 'transparent' }}>
                                            <div class="card-icon big">
                                                <div class="card-img">
                                                    <img src={GENERALKNOWLEDGETRIVIA} alt="Feature"></img>
                                                </div>
                                            </div>
                                            <div class="card-text">
                                                <h2 style={{ fontSize: '30px' }}>General Knowledge Trivia!</h2>
                                                <p>Test your General Knowledge skills by taking our quiz, you have to answer 10 questions in 30 seconds. Try it now.
                                                </p>
                                            </div>

                                            <div class="start-btn">
                                                <a class="btn-nav" style={{ width: '250px', color: 'white' }} onClick={() => checkLoggedInUserANDGotoTrivia('general-knowledge')}>Play Now</a>
                                            </div>
                                        </div>

                                    </div>

                                    {/* NEXT 3 SUBJECTS */}

                                    <div class="card-wrapper">
                                        <div class="card-single top" style={{ backgroundColor: 'transparent' }}>
                                            <div class="card-icon big">
                                                <div class="card-img">
                                                    <img src={PHYSICSTRIVIA} alt="Feature"></img>
                                                </div>
                                            </div>
                                            <div class="card-text">
                                                <h2 style={{ fontSize: '30px' }}>Physics Trivia!</h2>
                                                <p>Test your Physics skills by taking our quiz, you have to answer 10 questions in 30 seconds. Try it now.
                                                </p>
                                            </div>

                                            <div class="start-btn">
                                                <a class="btn-nav" style={{ width: '250px', color: 'white' }} onClick={() => checkLoggedInUserANDGotoTrivia('physics')}>Play Now</a>
                                            </div>
                                        </div>
                                        {/* 5''''''' */}
                                        <div class="card-single top" style={{ backgroundColor: 'transparent' }}>
                                            <div class="card-icon big">
                                                <div class="card-img">
                                                    <img src={CHEMISTRYTRIVIA} alt="Feature"></img>
                                                </div>
                                            </div>
                                            <div class="card-text">
                                                <h2 style={{ fontSize: '30px' }}>Chemistry Trivia!</h2>
                                                <p>Test your Chemistry skills by taking our quiz, you have to answer 10 questions in 30 seconds. Try it now.
                                                </p>
                                            </div>

                                            <div class="start-btn">
                                                <a class="btn-nav" style={{ width: '250px', color: 'white' }} onClick={() => checkLoggedInUserANDGotoTrivia('chemistry')}>Play Now</a>
                                            </div>
                                        </div>
                                        {/* 6====== */}
                                        <div class="card-single top" style={{ backgroundColor: 'transparent' }}>
                                            <div class="card-icon big">
                                                <div class="card-img">
                                                    <img src={BIOLOGYTRIVIA} alt="Feature"></img>
                                                </div>
                                            </div>
                                            <div class="card-text">
                                                <h2 style={{ fontSize: '30px' }}>Biology Trivia!</h2>
                                                <p>Test your Biology skills by taking our quiz, you have to answer 10 questions in 30 seconds. Try it now.
                                                </p>
                                            </div>

                                            <div class="start-btn">
                                                <a class="btn-nav" style={{ width: '250px', color: 'white' }} onClick={() => checkLoggedInUserANDGotoTrivia('biology')}>Play Now</a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div id="features" class="features-wrapper">
                        <div class="container">
                            <div class="card-wrapper">
                                <div class="card-single top">
                                    <div class="card-icon">
                                        <div class="card-img">
                                            <img src={featureTop1Image} alt="Feature"></img>
                                        </div>
                                    </div>
                                    <div class="card-text">
                                        <p>Consistent updates ensuring unlimited learning</p>
                                    </div>
                                </div>
                                <div class="card-single top">
                                    <div class="card-icon big">
                                        <div class="card-img">
                                            <img src={beeImage} alt="Feature" style={{ width: '15rem' }}></img>
                                        </div>
                                    </div>
                                    <div class="card-text">
                                        <p>Don’t believe us? Listen to what our users say</p>

                                    </div>
                                </div>
                                <div class="card-single top">
                                    <div class="card-icon">
                                        <div class="card-img">
                                            <img src={featureTop3Image} alt="Feature"></img>
                                        </div>
                                    </div>
                                    <div class="card-text">
                                        <p>Rating of 4.8 stars from our users</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div id="about" class="about-wrapper">
                            <div class="about-box">
                                <div class="about-inner">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-md-7 col-xs-12">
                                                <h2>QuizziBee gives you weekly rewards!</h2>
                                                <p>
                                                    Enter the Quizzibee universe to participate in our daily challenges. You can earn weekly rewards after finishing the first three challenges of the day. If you have the highest score, you will also be among the players who will receive Quizzibee awards. After a week, the top 250 players will get prizes, which will be revealed. Set up your profile and get going right away!</p>

                                            </div>
                                            <div class="col-md-5 col-xs-12">
                                                <img src={beeImage} alt=""></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ maxWidth: 'max-content', margin: 'auto' }}>
                            <Link className="btn-action center-block" to='/leaderboard' style={{ fontSize: '14px', width: '270px', justifyContent: 'center', paddingLeft: '5px', paddingRight: '5px' }} >Show Weekly Leader Board</Link>
                        </div>


                        {/* <!-- Review Section --> */}
                        <div id="reviews" class="review-slides" >
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-12">

                                        {/* <Slider {...settings} style={{ marginBottom: '40px'}}>
                                            <div class="review-box">
                                                <div class="review-text">
                                                    <div class="review-attr">
                                                        <div class="review-footer">
                                                            <h2>Robert Williams, 01.01.2020</h2>
                                                            <div class="score">
                                                                <div class="score-wrap">
                                                                    <div class="stars-active" style={{ width: '90%' }}>
                                                                        <i class="fas fa-star"></i>
                                                                        <i class="fas fa-star"></i>
                                                                        <i class="fas fa-star"></i>
                                                                        <i class="fas fa-star"></i>
                                                                        <i class="fas fa-star"></i>
                                                                    </div>
                                                                    <div class="stars-inactive">
                                                                        <i class="far fa-star"></i>
                                                                        <i class="far fa-star"></i>
                                                                        <i class="far fa-star"></i>
                                                                        <i class="far fa-star"></i>
                                                                        <i class="far fa-star"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h1>Most effective online website for my kids..</h1>
                                                    <p>Was unsure at first, but the portal totally changed my mind. Even started to use myself. Great portal.</p>
                                                </div>
                                            </div>

                                            <div class="review-box">
                                                <div class="review-text">
                                                    <div class="review-attr">
                                                        <div class="review-footer">
                                                            <h2>Daniel Tan, 10.02.2020</h2>
                                                            <div class="score">
                                                                <div class="score-wrap">
                                                                    <div class="stars-active" style={{ width: '85%' }}>
                                                                        <i class="fas fa-star"></i>
                                                                        <i class="fas fa-star"></i>
                                                                        <i class="fas fa-star"></i>
                                                                        <i class="fas fa-star"></i>
                                                                        <i class="fas fa-star"></i>
                                                                    </div>
                                                                    <div class="stars-inactive">
                                                                        <i class="far fa-star"></i>
                                                                        <i class="far fa-star"></i>
                                                                        <i class="far fa-star"></i>
                                                                        <i class="far fa-star"></i>
                                                                        <i class="far fa-star"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h1>Amazingly simple &amp; fast</h1>
                                                    <p>Finally a portal that is simple for everyone. Even those that can&#39;t speak English. Highly recommended​</p>
                                                </div>
                                            </div>

                                            <div class="review-box">
                                                <div class="review-text">
                                                    <div class="review-attr">
                                                        <div class="review-footer">
                                                            <h2>Katherine Edmunds. 03.02.2020</h2>
                                                            <div class="score">
                                                                <div class="score-wrap">
                                                                    <div class="stars-active" style={{ width: '95%' }}>
                                                                        <i class="fas fa-star"></i>
                                                                        <i class="fas fa-star"></i>
                                                                        <i class="fas fa-star"></i>
                                                                        <i class="fas fa-star"></i>
                                                                        <i class="fas fa-star"></i>
                                                                    </div>
                                                                    <div class="stars-inactive">
                                                                        <i class="far fa-star"></i>
                                                                        <i class="far fa-star"></i>
                                                                        <i class="far fa-star"></i>
                                                                        <i class="far fa-star"></i>
                                                                        <i class="far fa-star"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h1>I recommend this.</h1>
                                                    <p>It is good and effective for those who want to learn their English. It is a very useful program; good and easy to learn English.</p>
                                                </div>
                                            </div>

                                            <div class="review-box">
                                                <div class="review-text">
                                                    <div class="review-attr">
                                                        <div class="review-footer">
                                                            <h2>Sophie Watson. 14.05.2020</h2>
                                                            <div class="score">
                                                                <div class="score-wrap">
                                                                    <div class="stars-active" style={{ width: '100%' }}>
                                                                        <i class="fas fa-star"></i>
                                                                        <i class="fas fa-star"></i>
                                                                        <i class="fas fa-star"></i>
                                                                        <i class="fas fa-star"></i>
                                                                        <i class="fas fa-star"></i>
                                                                    </div>
                                                                    <div class="stars-inactive">
                                                                        <i class="far fa-star"></i>
                                                                        <i class="far fa-star"></i>
                                                                        <i class="far fa-star"></i>
                                                                        <i class="far fa-star"></i>
                                                                        <i class="far fa-star"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h1>Good site, good design, good everything</h1>
                                                    <p>I love it 10/10</p>
                                                </div>
                                            </div>
                                        </Slider> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Footer />
                    </div>
                </div>

            </div >
        </>
    );
};

export default Home;
