import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

const EditProfileModal = ({ show, onHide }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        // Reset form errors when name, email, or password changes
        setFormErrors({});
    }, [name, email, password]);

    const saveProfileInfoInDb = () => {
        const mymsisdn = sessionStorage.getItem("currentMsisdn");
        const cleanedMsisdn = mymsisdn.replace(/^"(.*)"$/, '$1');
        const apiUrl = `http://api.quizzibee.com/api/save-profile?msisdn=${encodeURIComponent(cleanedMsisdn)}&name=${encodeURIComponent(name)}&password=${encodeURIComponent(password)}&email=${encodeURIComponent(email)}`;

        fetch(apiUrl)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // console.log(data);
                setName('');
                setEmail('');
                setPassword('');
                onHide();
                window.location.reload();
                // Handle success message
            })
            .catch(error => {
                console.error('Error:', error);
                // Handle error message
            });
    };

    const handleSaveProfile = (event) => {
        event.preventDefault(); // Prevent the default form submission
        // Validate the fields
        let errors = {};
        if (name.trim() === '') {
            errors.name = 'Name cannot be empty';
        }
        if (email.trim() === '') {
            errors.email = 'Email cannot be empty';
        } else if (!isValidEmail(email)) {
            errors.email = 'Invalid email format';
        }
        if (password.trim() === '') {
            errors.password = 'Password cannot be empty';
        }

        // If errors exist, set them and return
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }

        // If no errors, proceed with saving profile
        saveProfileInfoInDb();
    };

    const isValidEmail = (email) => {
        // Basic email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return (
        <Modal className="modal fade" show={show} onHide={onHide} backdrop="static" keyboard={false} style={{ zIndex: '99999' }}>
            <Modal.Header className="modal-header" closeButton>
                <Modal.Title className="modal-title">Update Your Information</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <Form>
                    <Form.Group controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter your name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            isInvalid={!!formErrors.name}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formErrors.name}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            isInvalid={!!formErrors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formErrors.email}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter your password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            isInvalid={!!formErrors.password}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formErrors.password}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSaveProfile} style={{background: '#e9c525'}}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditProfileModal;
