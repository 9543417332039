import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from "./logo.png";
import './App.css';
import LoginModal from './components/LoginModal';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import MessageBox from './MessageBox';

const Navbar = ({ wantToGoBack }) => {
    let navigate = useNavigate();
    const [isNavbarCollapsed, setNavbarCollapsed] = useState(true);
    const modalRef = useRef(null);
    const [isModalVisible, setModalVisible] = useState(false); // Set the initial visibility state
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const checkSessionStorage = sessionStorage.getItem('currentMsisdn');

    var newValue = false;
    const handleLogin = async (userInfo) => {
        // console.log('User logged in: inside navbar', userInfo);
        setIsLoggedIn(true); // Wait for the state to be updated
        hideModal();
        // Do any other logic you need with the user information
    };

    const handleButton = async () => {
        const result = await MessageBox.open({
            content: <p>"Logged out!"</p>,
            buttons: [
                {
                    name: "ok", handler: () => {
                        navigate('/');
                    }
                }
            ]
        });
    };

    useEffect(() => {
        // Use another useEffect to handle logic after userInfo is updated
        if (checkSessionStorage) {
            setIsLoggedIn(true);
        }
    }, [checkSessionStorage]);


    useEffect(() => {
        // Use another useEffect to handle logic after userInfo is updated
        if (isLoggedIn) {
            newValue = true;
        }
    }, [isLoggedIn]);

    const showModal = () => {
        // console.log("inside")
        setModalVisible(true);
    };

    const hideModal = () => {
        setModalVisible(false);
    };

    const handleOutsideClick = (event) => {
        if (event.target === modalRef.current) {
            hideModal();
        }
    };

    const subscribe = () => {
        if (wantToGoBack) {
            wantToGoBack();
        } else {
            navigate('/subscribe');
        }
    };

    const onLogoClick = () => {
        if (wantToGoBack) {
            wantToGoBack();
        } else {
            navigate('/');
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const navbar = document.querySelector('.navbar');
            if (window.scrollY > 0) {
                navbar.classList.add('past-main');
            } else {
                navbar.classList.remove('past-main');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty dependency array means this effect runs once when the component mounts

    const handleNavbarToggle = () => {
        setNavbarCollapsed(!isNavbarCollapsed);
    };

    const handleLogout = () => {
        if (wantToGoBack) {
            wantToGoBack();
        } else {
            // console.log("logout clicked!");
            // console.log(isLoggedIn);
            setIsLoggedIn(false);
            sessionStorage.removeItem('currentMsisdn');
            handleButton();
        }

    }

    return (
        <>
            <nav className={`navbar navbar-expand-md navbar-light bg-white ${isNavbarCollapsed ? '' : 'shadow'}`} style={{ position: 'fixed' }}>
                <div className="container" style={{ display: 'flex' }}>
                    <a className="navbar-brand" onClick={onLogoClick}>
                        <img src={logo} width="300" height="73" alt="" />
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        onClick={handleNavbarToggle}
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                        className={`collapse navbar-collapse ${isNavbarCollapsed ? '' : 'show'}`}
                    >
                        <ul className="navbar-nav ml-auto">
                            {isLoggedIn && (
                            <li className="nav-item">
                                <Link className="nav-link"to={'/profile'} >
                                    Profile
                                </Link>
                            </li>
                            )}
                            <li className="nav-item">
                                <Link className="nav-link" to={'/leaderboard'}>
                                    Top Players
                                </Link>
                            </li>
                        </ul>
                        <div className="login-wrapper">
                            {isLoggedIn ? (
                                // If user is not logged in, show Login button
                                <button className="btn-nav" onClick={handleLogout}>
                                    Logout
                                </button>
                            ) : (

                                // If user is logged in, show Logout button
                                <button className="btn-nav" onClick={showModal}>
                                    Login
                                </button>
                            )}
                        </div>
                        {isLoggedIn ? null : (
                            <div className="login-wrapper">
                                <a className='btn-nav' style={{ margin: '12px', background: 'linear-gradient(90deg, #fab41f 0%, #f7cc3d 100%)' }} onClick={subscribe}>Subscribe</a>
                            </div>
                        )}
                    </div>
                </div>
            </nav>

            {/* <!-- Wrapper -->
    <!-- Login and Signup Modal --> */}
            <LoginModal show={isModalVisible} onHide={hideModal} onLogin={handleLogin} />


        </>

    );
};

export default Navbar;
