import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Captcha = () => {

    return (
        <div>
            <h1>
                Captcha
            </h1>
        </div>
    );
};

export default Captcha;
