import React, { useEffect, useState } from 'react';
import logo from './logo.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  const [hasUserInformation, setHasUserInformation] = useState(false);

  useEffect(() => {
    const storedUser = sessionStorage.getItem('currentMsisdn');
    setHasUserInformation((prevValue) => {
      const newValue = !!storedUser;
      return newValue;
    });
  }, [sessionStorage.getItem('currentMsisdn')]); // Add session storage as a dependency

  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4 offset-md-2 ">
            <ul className="footer-menu">
              {hasUserInformation ? (
                <>
                  <div>
                    <li className="footerListItems"><Link to={'/faq'}>FAQ</Link></li>
                    <li className="footerListItems"><Link to={'/terms-n-conditions'}>TnC</Link></li>
                    <li className="footerListItems"><Link to={'/contact'}>Contact Us</Link></li>
                  </div>
                  <div>
                  <li className="footerListItems"><Link to={'/profile'}>Unsubscribe</Link></li>
                  <li className="footerListItems"><Link to={'/profile'}>Profile</Link></li>
                  </div>
                </>
              ) : (
                <>
                  <li className="footerListItems"><Link to={'/faq'}>FAQ</Link></li>
                  <li className="footerListItems"><Link to={'/terms-n-conditions'}>TNC</Link></li>
                  <li className="footerListItems"><Link to={'/contact'}>Contact Us</Link></li>
                </>
              )}
            </ul>
          </div>
          <div className="col-md-4 col-sm-12 text-center">
            <div className="footer-logo">
              <img className="img-fluid" src={logo} alt="Footer Logo" />
            </div>
          </div>
          <div className="col-md-12">
            <div className="footer-copyright">
              <p>&copy; 2024 Quizzibee. All rights reserved.</p>
            </div>
          </div>
        </div>
        {/* Scroll To Top */}
        <a id="back-top" className="back-to-top js-scroll-trigger" href="#main"></a>
        {/* Scroll To Top Ends */}
      </div>
    </div>
  );
};

export default Footer;
