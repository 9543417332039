import React, { useState, useEffect } from "react";
import "./Game.css";
import img0 from "./images/0.png";
import img1 from "./images/1.png";
import img2 from "./images/2.png";
import img3 from "./images/3.png";
import img4 from "./images/4.png";
import img5 from "./images/5.png";
import lost from './images/lost.gif';
import victory from './images/victory.gif';
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";

const NormalGame = () => {
    const [nWrong, setNWrong] = useState(0);
    const [guessed, setGuessed] = useState(new Set());
    const [showModal, setShowModal] = useState(false);
    const [wordsList, setWordsList] = useState([]);
    const [wordsListLoaded, setWordsListLoaded] = useState(false);
    const [apiError, setApiError] = useState(null);
    const [gameResult, setGameResult] = useState(null);
    const [playCount, setPlayCount] = useState(0);
    const [answer, setAnswer] = useState("");
    const [hint, setHint] = useState("");
    const [images] = useState([img0, img1, img2, img3, img4, img5]);
    const [maxWrong] = useState(5);
    const [gameStarted, setGameStarted] = useState(false); // Step 1
    const navigate = useNavigate();

    useEffect(() => {
        fetchWords();
    }, []);

    const fetchWords = async () => {
        const mymsisdn = sessionStorage.getItem("currentMsisdn");

        if (mymsisdn === null) {
            navigate('/');
        }
        else {

            const cleanedMsisdn = mymsisdn.replace(/^"(.*)"$/, '$1');

            const sendPinUrl = `http://api.quizzibee.com/api/get-normal-words?top=${encodeURIComponent(1)}&msisdn=${encodeURIComponent(cleanedMsisdn)}`;
            try {
                const response = await fetch(sendPinUrl);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();

                if (data) {
                    const randomIndex = Math.floor(Math.random() * data.length);
                    const randomWord = data[randomIndex];
                    const word = { word: randomWord.words, hint: randomWord.hint };
                    // console.log("data", data);
                    // console.log("index", data[randomIndex]);
                    // console.log("word", word);
                    setWordsList(data[randomIndex]);
                    setAnswer(randomWord.words.toLowerCase());
                    setHint(randomWord.hint);
                    setWordsListLoaded(true);
                } else {
                    throw new Error('Empty response received');
                }
            } catch (error) {
                console.error("Error fetching words:", error);
                setApiError(error.message);
            }
        }
    };

    useEffect(() => {
        if (wordsListLoaded) {
            setGameStarted(true); // Step 2
        }
    }, [wordsListLoaded]);

    useEffect(() => {
        if (gameStarted && !showModal) { // Step 3
            if (gameWon(answer, guessed)) {
                setGameResult("won");
                setShowModal(true);
            } else if (gameLost()) {
                setGameResult("lost");
                setShowModal(true);
            }
        }
    }, [nWrong, guessed]);

    const guessedWord = () => {
        return answer.split("").map((ltr, index) => (
            <li key={index} className={guessed.has(ltr) ? 'visible' : 'hidden'}>
                {guessed.has(ltr) ? ltr : ""}
            </li>
        ));
    };

    const generateButtons = () => {
        return "abcdefghijklmnopqrstuvwxyz".split("").map((ltr, index) => (
            <button
                key={index}
                value={ltr}
                onClick={() => handleGuess(ltr)}
                disabled={guessed.has(ltr)}
            >
                {ltr}
            </button>
        ));
    };

    const resetGame = () => {
        setNWrong(0);
        setPlayCount(playCount + 1);
        setGuessed(new Set());
        setShowModal(false);
        fetchWords();
    };

    const handleGuess = (ltr) => {
        setGuessed(prevGuessed => new Set(prevGuessed.add(ltr)));
        setNWrong(prevNWrong => prevNWrong + (answer.includes(ltr) ? 0 : 1));
    };

    const gameWon = (answer, guessed) => {
        const isWordGuessed = [...new Set(answer)].every(char => guessed.has(char));
        return isWordGuessed;
    };

    const gameLost = () => {
        return nWrong >= maxWrong;
    };

    return (
        <>
            <Navbar />
            <div className='Hangman-Card' style={{ marginTop: '40px' }}>
                <div className='Hangman'>
                    <div className="Hangman-Box">
                        <img src={images[nWrong]} alt="sample" />
                        <h1>Hangman Game</h1>
                    </div>
                    <div className='Hangman-info'>
                        <h4>Incorrect guesses: <b className="IncorrectCounter">{nWrong}/5</b></h4>

                        {showModal && (
                            <div className={`game-modal ${showModal ? 'show' : ''}`}>
                                <div className="content">
                                    {gameResult === "won" ? (
                                        <>
                                            <img src={victory} alt="gif"></img>
                                            <h4>Congrats!</h4>
                                            <p>You found the word: <b>{answer}</b></p>
                                        </>
                                    ) : (
                                        <>
                                            <img src={lost} alt="gif"></img>
                                            <h4>Game Over!</h4>
                                            <p>The correct word was: <b>{answer}</b></p>
                                        </>
                                    )}
                                    <button className="play-again" onClick={() => resetGame()}>Play Again</button>
                                </div>
                            </div>
                        )}

                        {!showModal && (
                            <>
                                <ul className="word-display">
                                    {guessedWord()}
                                </ul>
                                <p className='Hangman-hint'>Hint: <b>{hint}</b></p>
                                <p className='Hangman-btns'>{generateButtons()}</p>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default NormalGame;
